import React from 'react';
import styles from './SignupForm.module.scss';

const SignupForm = () => {
  return (<div className={styles['box']}>
        <div className={styles['intro-text']}>
            <h2>What's next?</h2>
            <a href="https://twitter.com/bascodes?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-show-count="false">Follow @bascodes</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <br />
            To receive more articles like that, signup for my newsletter.<br />
            I promise I won't spam you and send updates only occasionally.
            Because I hate full inboxes as much as you do!
        </div>
        <div id="substack-embed">
        <iframe src="https://basc.substack.com/embed" width="480" height="320" style={{border: '1px solid #EEE', background: 'white'}} frameborder="0" scrolling="no"></iframe>
        </div>

    </div>
  );
};

export default SignupForm;